import React from 'react'

const Footer = () => {
  // Footer component
    return (
      <footer className='footer'>
       
      </footer>
    );

}

export default Footer