import { Flex } from '@chakra-ui/react'
import React from 'react'

const Home = () => {
  return (
    <Flex bg="#fefaed" h='calc(100vh - 120px)'>
      Home
    </Flex>
  )
}

export default Home